import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "disabled", "placeholder", "options", "selectedOption", "name", "dataAnalytics", "ariaLabel"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Select.style';
var Select = function Select(_ref) {
  var id = _ref.id,
    className = _ref.className,
    disabled = _ref.disabled,
    placeholder = _ref.placeholder,
    options = _ref.options,
    selectedOption = _ref.selectedOption,
    name = _ref.name,
    dataAnalytics = _ref.dataAnalytics,
    ariaLabel = _ref.ariaLabel,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: "".concat(className, " select-field-container")
  }, __jsx("select", _extends({
    className: "nva-select",
    id: id || name,
    name: name,
    value: selectedOption,
    disabled: disabled,
    "aria-label": ariaLabel
  }, others), placeholder && __jsx("option", {
    value: ""
  }, placeholder), options.map(function (opt) {
    return __jsx("option", {
      className: "nva-select__option",
      key: opt.label,
      value: opt.value,
      "data-analytics-type": opt.dataAnalytics && opt.dataAnalytics.type ? opt.dataAnalytics.type : '',
      "data-analytics-value": opt.dataAnalytics && opt.dataAnalytics.value ? opt.dataAnalytics.value : '',
      "data-analytics-variation": opt.dataAnalytics && opt.dataAnalytics.variation ? opt.dataAnalytics.variation : ''
    }, opt.label);
  })));
};
Select.defaultProps = {
  disabled: false,
  selectedOption: [],
  placeholder: '',
  className: ''
};
export default styled(Select).withConfig({
  componentId: "sc-1j2zwng-0"
})(["", ";"], styles);
export { Select as SelectVanilla };