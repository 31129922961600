import { css } from 'styled-components';
var styles = css(["position:relative;&:after{content:'';width:0;height:0;border-left:7px solid transparent;border-right:7px solid transparent;border-top:10px solid var(--sc-select--arrow-color,", ");position:absolute;right:16px;top:52%;transform:translateY(-50%);pointer-events:none;}select{height:60px;background-color:var(--sc-select--bg-color,", ");color:var(--sc-select--text-color,", ");width:100%;display:block;padding:16px 22px;margin-top:var(--sc-spacing-3,10px);border-style:solid;border-width:2px;border-radius:0;border-color:var(--sc-select--border-color,", ");appearance:none;}select::-ms-expand{display:none;}"], function (props) {
  var _props$theme, _props$theme$select;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$select = _props$theme.select) === null || _props$theme$select === void 0 ? void 0 : _props$theme$select.arrowColor;
}, function (props) {
  var _props$theme2, _props$theme2$select;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$select = _props$theme2.select) === null || _props$theme2$select === void 0 ? void 0 : _props$theme2$select.backgroundColor;
}, function (props) {
  var _props$theme3, _props$theme3$select;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$select = _props$theme3.select) === null || _props$theme3$select === void 0 ? void 0 : _props$theme3$select.textColor;
}, function (props) {
  var _props$theme4, _props$theme4$select;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$select = _props$theme4.select) === null || _props$theme4$select === void 0 ? void 0 : _props$theme4$select.borderColor;
});
export default styles;